import { Component, useContext } from 'react';
import { ApiContext } from '../api/context';
import CenterWrapper from './CenterWrapper';
import logo from '../assets/images/LogoTransparent.png'

class Login extends Component {

    doLogin = () => {
        this.props.api.authService.login();
    }

    render() {
        return (
            <CenterWrapper>
                    <div className="p-b-160 p-t-50">
                        <img alt="Logo" width="270px" className="p-b-43" src={logo} />

                        <div className="container-form-btn">
                            <button onClick={this.doLogin} className="steambutton">
                                <span>Login With Steam</span>
                                <div className="icon">
                                    <i className="fa fa-steam-square"></i>
                                </div>
                            </button>
                        </div>
                    </div>
            </CenterWrapper>
        );
    }
}

export default function Wrapper(props) {
    const apiContext = useContext(ApiContext);
    return <Login {...props} api={apiContext} />
}